 /**
 * Built using Imarc Boilerplate v2.1
 *
 * Copyright 2016 Imarc
 * Licensed under the Apache License v2.0
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * = Table of Contents =
 * Variables
 * Mixins
 * Base
 * Layout
 * Typography
 * Forms
 * Iconography
 * Messaging
 * Tables
 * Section Specific
 * Page Specific
 * Components
 * Media Queries
 * Print Styles
 */

 @import "components";

 /* = Variables = */
 $font-stack: "Open Sans", Arial, sans-serif;
 $font-header: "Sanchez", serif;;


 /* Grays */
 $white:     #FFF;
 $lightgray: #DDD;
 $gray:      #808080;
 $darkgray:  #4A4A4A;
 $charcoal:  #191919;
 $black:     #000;

 /* The brand colors (name them the color) */
 $brand-navy: #0d1640;
 $brand-orange: #EB7424;
 $brand-accent-blue: #5e699d;
 $brand-navy: #0e173d;
 $brand-bright-blue: #3474af;

 /* Messaging colors */
 $success: #48AC2C;
 $error: #CE2A2A;
 $info: #DDD;

 /* Applied colors */
 $plain-text: $charcoal;
 $meta-text: $gray;
 $link: $white;
 $link-hover: $brand-accent-blue;
 $border: lighten($charcoal, 80%);

 /* Social Network Colors */
 $facebook: #3664A2;
 $twitter: #55ACEE;
 $linkedin: #0077B5;
 $youtube: #CD201F;

 /* Breakpoint values */
 $medium: 669px;
 $large: 1024px;


 /* = Mixins = */

 @mixin button {
     background-color: #fff;
     border: 3px solid transparent;
     color: $brand-navy;
     display: inline-block;
     font-family: $font-header;
     font-weight: bold;
     padding: 1rem 1.5rem;
     text-align: center;
     text-transform: uppercase;
     vertical-align: top;
     transition: all 0.2s;

     &:hover,
     &:focus {
         background-color: rgba(255,255,255,0);
         border-color: #fff;
         color: $white;
     }

     &.disabled,
     &[disabled] {
         background-color: lighten($charcoal, 45%);
         color: darken(#FFF, 20%);
         pointer-events: none;
     }
 }

 @mixin container {
     margin-left: auto;
     margin-right: auto;
     max-width: 1200px;
     padding-left: 1rem;
     padding-right: 1rem;
     position: relative;
 }

 @mixin grouping {
     &::before,
     &::after {
         content: "";
         display: table;
     }
     &::after {
         clear: both;
     }
 }

 @mixin fontawesome($character: "") {
     font: normal normal normal 1em unquote("/") 1 FontAwesome;
     -webkit-font-smoothing: antialiased;
     -moz-osx-font-smoothing: grayscale;
     speak: none;
     text-rendering: auto;
     text-transform: none;

     @if $character != "" {
         content: $character;
     } @else {
         display: inline-block;
     }
 }

 @mixin messaging {
     margin-bottom: 2rem;
     padding: 1em 3em;
     position: relative;
     p:last-of-type,
     ul:last-of-type {
         margin-bottom: 0;
     }
     a {
         text-decoration: underline;
     }
     &::before {
         @include fontawesome;

         content: "";
         display: block;
         font-size: 20px;
         height: 23px;
         left: 1em;
         position: absolute;
         top: 1em;
         width: 23px;
     }
 }

 @mixin no-bullets {
     list-style: none;
     margin: 0;
     padding-left: 0;

     li::before {
         display: none;
     }
 }

 @mixin transition($properties: all, $duration: 250ms, $easing: ease-in-out) {
     @if length($properties) > 1 {
         -webkit-transition-property: $properties;
         transition-property: $properties;
     } @else {
         -webkit-transition: $properties $duration $easing;
         transition: $properties $duration $easing;
     }
 }

 @mixin visuallyhidden {
     border: 0;
     clip: rect(0 0 0 0);
     height: 1px;
     margin: -1px;
     overflow: hidden;
     padding: 0;
     position: absolute;
     width: 1px;
 }


 /* = Base = */
 /* Not Included:
  *   - bi-directional elements: bdi, bdo
  *   - ruby elements: data, output, rp, rt, rtc, ruby, samp, time, var
  *   - typographical elements: del, dfn, ins, kbd, mark, s, small, wbr
  *   - HTML5 elements: col, colgroup, details, dialog, map, menu, menuitem, summary
  */
 a, abbr, address, article, aside, audio, b, blockquote, body, button, canvas, caption, cite, code, datalist, dd, div, dl, dt, em, embed, fieldset, figcaption, figure, footer, form, h1, h2, h3, h4, h5, h6, header, hgroup, html, hr, i, iframe, img, input, label, legend, li, main, meter, nav, object, ol, option, p, pre, progress, q, section, select, span, strong, sub, sup, table, tbody, td, textarea, tfoot, th, thead, tr, u, ul, video {
     box-sizing: border-box;
     margin: 0;
     padding: 0;
     border: 0;
     outline: 0;
     font-size: 100%;
     font: inherit;
     vertical-align: baseline;
 }

 article, aside, audio, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, video {
     display: block;
 }

 html {
     background-color: #000;
     color: $plain-text;
     font-family: $font-stack;
     font-size: 16px;
     letter-spacing: 1px;
     line-height: 1.5;
     -webkit-text-size-adjust: 100%;
     -ms-text-size-adjust: 100%;
 }

 body {
     background-color: #000;
     color: #fff;
     min-width: 300px;
 }

 img {
     border: 2px solid #fff;
     box-shadow: 5px 5px 0px rgba(255, 255, 255, 0.5);
     max-width: 100%;
     vertical-align: middle;
 }

 figcaption {
    color: $lightgray;
    font-style: italic;
    margin-top: 0.5rem;
    text-align: center;
 }

 ::selection {
     background: $border;
 }

 ::-moz-selection {
     background: $border;
 }


 /* = Layout = */
 .group {
     @include grouping;
 }

 .container {
     @include container;
 }

 .columns {
     @include columns;
 }

 .carousel {
     @include carousel;
 }

 .wrapper {
     overflow: hidden;
 }

 .sr-only {
     @include visuallyhidden;
 }

 header.primary {
     background-color: $charcoal;
     padding: 1rem 0;
     .container {
         position: static;
     }
     .menu-toggle {
         background: none;
         height: 3rem;
         position: absolute;
         right: 0.5rem;
         top: 3rem;
         width: 3rem;
         z-index: 2000;
         &:focus,
         &:hover {
             background: none;
         }
         .menu-bar {
             border-radius: 0;
             display: block;
             left: 0;
             position: absolute;
             transition: .5s;
             width: 100%;
         }

         .menu-bar-middle {
             height: 5px;
             background-color: #626262;
             margin-top: 10px;
             margin-bottom: 10px;
             top: 3px;
         }

         .menu-bar-top {
             border: 5px solid #626262;
             border-bottom: none;
             top: 0;
         }

         .menu-bar-bottom {
             border: 5px solid #626262;
             border-top: none;
             top: 26px;
         }
         &.open {
             .menu-bar-top {
                 transform: rotate(45deg) translate(8px, 8px);
                 transition: .5s;
             }
             .menu-bar-middle {
                 transform: translate(230px);
                 transition: .1s ease-in;
                 opacity: 0;
             }
             .menu-bar-bottom {
                 transform: rotate(-45deg) translate(8px, -7px);
                 transition: .5s;
                 top: 22px;
             }
         }
     }
 }

 .branding {
     a,
     img {
         border: none;
         box-shadow: none;
         display: block;
         max-width: 150px;
     }
 }

 nav {
     @include grouping;
     ul {
         @include no-bullets;
         text-align: right;
     }
     a {
         color: #FFF;
         display: block;
         padding: 0 0.5rem;
     }
 }

 nav.primary {
     margin-bottom: 0;
     > ul {
         li {
             font-size: 1.5rem;
             line-height: 1;
             margin-bottom: 2rem;
             text-align: right;
             a {
                 color: #fff;
                 display: inline-block;
                 padding: 1rem 1.5rem;
                 position: relative;
                 transition: color 0.2s ease-in-out;
                 z-index: 20;
                 &::after {
                     background: $lightgray;
                     content: "";
                     height: 100%;
                     position: absolute;
                     right: 0;
                     top: 0;
                     transition: width 0.2s ease-in-out;
                     width: 2px;
                     z-index: -1;
                 }
             }
         }
         li.active,
         li:hover {
             > a {
                 color: $brand-navy;
                 &::after {
                     width: 100%;
                 }
             }
         }
     }
 }

 .utilities {
     nav.utility {
         margin-bottom: 0.4rem;
         ul {
             text-align: right;
         }
         li {
             font-family: $font-stack;
             font-size: 1.5rem;
             text-transform: none;
         }
         a {
             &:hover {
                 color: $gray;
             }
         }
     }
 }

 nav.utility {
     margin-bottom: 0.7rem;
     a {
         font-size: 0.8rem;
     }
 }

 nav.secondary {
     .button.alt {
         display: none;
         border-color: #fff;
         color: #fff;
         padding: 1rem 1.5rem;
         &:hover {
             background: #fff;
             color: $brand-navy;
         }
     }
     .contact {
         a {
             display: inline-block;
             padding: 0;
         }
         span {
             display: inline-block;
             padding: 0.5rem 0.5rem 0;
         }
     }
 }

 .mobile {
     @include mobileNavigation;
 }

 .header-content > .dropdown {
     @include mobileNavigation;
     z-index: 1001;
     background: lighten($charcoal, 5%);
     &.sub-menu {
         z-index: 1002;
         background: lighten($charcoal, 10%);
     }

 }

 .desktop {
     display: none;
     flex-flow: column wrap;
     align-items: flex-end;
     justify-content: space-between;
     flex-basis: 80%;
     max-width: 80%;

     nav.primary {
         flex-basis: 70%;
         max-width: 70%;
         align-self: flex-start;
     }
     .utilities {
         flex-basis: 30%;
         max-width: 30%;
     }
 }

 .header-content {
     align-items: center;
     display: flex;
     flex-flow: row wrap;
     justify-content: space-between;
 }

 form.search {
     display: -webkit-flex;
     display: flex;
     .text,
     .submit {
         margin-bottom: 0;
     }
     .text {
         width: 100%;
     }
     label {
         @include visuallyhidden;
     }
     input {
         border: none;
         width: 100%;
     }
 }

 .torso {
     @include grouping;
     padding-bottom: 0;
 }

 main section {
     padding: 1.5rem 1rem;
 }

 aside.primary {
     margin-bottom: 2rem;
 }

 footer.primary {
     background-color: $charcoal;
     color: $meta-text;
     font-size: 0.9rem;
     padding-bottom: 1rem;
     padding-top: 1rem;
     text-align: center;
     nav {
         margin-bottom: 1rem;
     }
     p {
         margin: 0;
     }
     a {
         color: #FFF;
     }
     nav li {
         display: inline-block;
         margin: 0 0.5rem;
     }
     .location {
        text-align: left;
     }
     .address {
        margin-bottom: 0.5rem;
     }
     .copyright {
        font-size: 0.65rem;
        opacity: 0.5;
     }
 }

 .social {
     @include no-bullets;

     margin-bottom: 1rem;
     li {
         display: inline-block;
         font-size: 22px;
         margin-bottom: 0;
         margin-right: 0.4rem;
     }
     a:hover {
         opacity: 0.7;
     }
 }


 /* = Typography = */
 h1,
 h2,
 h3,
 h4,
 h5 {
     font-family: $font-header;
     line-height: 1.2;
     margin-bottom: 2rem;
     text-align: center;
     text-transform: uppercase;
 }

 h1 {
     font-size: 1.75rem;
 }

 h2 {
     font-size: 2.125rem;
 }

 h3 {
     font-size: 1.75rem;
     color: #939598;
 }

 h4 {
     font-size: 1.25rem;
 }

 h5 {
     font-size: 1.125rem;
 }

 strong {
     font-weight: bold;
 }

 em {
     font-style: italic;
 }

 sup {
     color: $gray;
     font-size: 0.875rem;
     vertical-align: super;
 }

 .intro {
     color: $lightgray;
     margin: 0 auto;
     max-width: 700px;
     text-align: center;
     h4 {
        margin-bottom: 0.5rem;
     }
     p {
         font-size: 1.5rem;
         margin-bottom: 0.8em;
     }
 }

 .guide {
     color: $meta-text;
     font-size: 1.25rem;
 }

 .meta {
     color: $meta-text;
 }

 .note {
     color: $meta-text;
     font-size: 0.975rem;
 }

 hr {
     border: 0;
     border-top: 1px solid $meta-text;
     display: block;
     height: 0;
     margin: 2em auto 3em;
     width: 100%;
 }

 @mixin link {
     color: $link;
     cursor: pointer;
     text-decoration: none;

     &:hover,
     &:active {
         color: $link-hover;
     }
     &:focus {
         outline: thin dotted;
     }

     &.disabled,
     &[disabled] {
         color: darken(#FFF, 20%);
         cursor: default;
         pointer-events: none;
     }
 }
 a {
     @include link;
 }

 p,
 ol,
 ul,
 dl {
     margin-bottom: 2rem;
 }

 ol,
 ul {
     padding-left: 2rem;
     li,
     dt,
     dd {
         margin-bottom: 0.5em;
     }
 }

 ul {
     list-style: disc;
 }

 dt {
     font-weight: bold;
     padding-left: 0.5em;
 }

 dd {
     padding-left: 2em;
 }

 pre {
     background-color: #4A4A4A;
     padding: 1em;
     white-space: pre-wrap;
     word-wrap: break-word;
 }

 code {
     color: #FBCE78;
     font-family: "Consolas", monospace;
     padding: 2px 4px;
 }


 /* = Forms = */
 button,
 input,
 optgroup,
 select,
 textarea {
     font: inherit;
     margin: 0;
 }

 .button,
 .checkboxes,
 .file,
 .password,
 .radios,
 .reset,
 .select,
 .submit,
 .text,
 .textarea {
     margin-bottom: 2rem;
 }

 label {
     cursor: pointer;
     display: block;
     font-weight: bold;
     margin-bottom: 0.5em;
 }

 .required {
     color: $error;
 }

 fieldset .help {
     color: $meta-text;
     display: block;
     font-size: 0.8rem;
     margin: 0;
 }

 input[type="date"],
 input[type="datetime-local"],
 input[type="email"],
 input[type="month"],
 input[type="number"],
 input[type="password"],
 input[type="search"],
 input[type="tel"],
 input[type="text"],
 input[type="time"],
 input[type="url"],
 input[type="week"],
 textarea {
     border: 1px solid $border;
     box-sizing: border-box;
     color: $plain-text;
     display: block;
     font-family: $font-stack;
     margin: 0;
     padding: 0.4em 0.6em;
     width: 100%;
 }

 ::-webkit-input-placeholder {
     color: #4A4A4A;
 }
 ::ms-input-placeholder {
     color: #4A4A4A;
 }
 ::-moz-placeholder {
     color: #4A4A4A;
 }
 ::-placeholder {
     color: #4A4A4A;
 }

 .multiple select {
     padding-left: 5px;
     padding-right: 10px;
 }

 textarea {
     height: 8em;
     overflow: auto;
     resize: vertical;
 }

 .radio,
 .checkbox {
     font-weight: normal;
 }

 input[type="checkbox"],
 input[type="radio"] {
     padding: 0;
 }

 a.button {
     @include button;
     margin-bottom: 0;
     &.alt {
         background: none;
         padding: 2rem;
         border: 2px solid $brand-navy;
         &:hover {
             background-color: rgba(13, 22, 64, 0.75);
         }
     }
 }

 button:not(.link),
 input[type="button"]:not(.link),
 input[type="reset"]:not(.link),
 input[type="submit"]:not(.link) {
     @include button;
     border: none;
     cursor: pointer;
 }

 button.link,
 input[type=button].link,
 input[type="button"].link,
 input[type="reset"].link,
 input[type="submit"].link {
     @include link;
     background: transparent;
 }


 /* = Iconography = */
 .thumbs-up::before {
     @include fontawesome("\f087");

     margin-right: 0.5em;
 }

 i.fa {
     color: $brand-accent-blue;
 }


 /* = Messaging = */
 .success {
     @include messaging;

     background-color: $success;
     &::before {
         content: "\f00c";
     }
     a {
         color: #FFF;
     }
     a:hover {
         color: darken(#FFF, 15%);
     }
 }

 .info {
     @include messaging;

     background-color: $info;
     &::before {
         content: "\f129";
     }
 }

 .error {
     @include messaging;

     background-color: $error;
     &::before {
         content: "\f12a";
     }
     a {
         color: $info;
     }
     a:hover {
         color: darken($info, 15%);
     }
 }


 /* = Tables = */
 table {
     border-collapse: collapse;
     border-spacing: 0;
     margin-bottom: 2rem;
     width: 100%;
     caption {
         font-size: 0.9rem;
         margin-bottom: 0.75em;
     }
     td,
     th {
         padding: 0.5em 1em;
         text-align: left;
         vertical-align: top;
     }
     th {
         background-color: $plain-text;
         color: #FFF;
         font-weight: bold;
         text-transform: uppercase;
     }
     td {
         border: 1px solid $border;
     }
     tr:nth-child(even) td {
         background-color: #F3F3F3;
     }
 }

 /* = Section Specific = */


 /* =  Page Specific = */

 // homepage
 .tiles.col-2 {
     @include tiles($columns: 2);
 }

 // about
 section.about {
     .container > div {
         display: flex;
         flex-direction: column;
     }
     .asset {
         margin-bottom: 2rem;
     }
     p {
        font-size: 1rem;
     }
 }

 .contact-info {
    margin-bottom: 0;
    p {
        font-size: 1.25rem;
        text-align: center;
    }
 }

 /* = Components = */
 .backgroundvideo {
     @include backgroundvideo();
 }

 .dossier {
     @include dossier($gutter: 2rem, $photoWidth: 25%, $direction: row);
 }

 nav.primary {
     @include dropdown();
     li.mega {
         @include dropdown-mega();
     }
 }

 .features {
     @include features($gutter: 3rem);
 }

 footer.primary {
     @include footer();
 }

 header.primary {
     @include sticky-header();
 }

 .listing {
     @include listing();
 }

 .login {
     @include login;
 }

 nav.pagination {
     @include pagination();
 }

 header.primary {
     @include sticky-header();
 }

 .tabs {
     @include tabs();
 }

 .testimonial {
     @include testimonial;
 }

 .tiles {
     @include tiles();
 }

 .accordions {
     @include accordion();
 }

 /* site components */

 .hero {
     background: $brand-navy url(/public/img/nyc-skyline.png) no-repeat center bottom / 100% auto;
     color: #fff;
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: column;
     flex-direction: column;
     -webkit-justify-content: center;
     justify-content: center;
     padding: 2rem 0;
     text-align: center;
     h1 {
         margin-bottom: 0;
         text-transform: uppercase;
     }
     h2 {
         font-size: 1.5rem;
     }
     h2:last-child {
         margin-bottom: 0;
     }
     p.meta {
         font-size: 1.75rem;
         margin-bottom: 1rem;
         text-transform: uppercase;
     }
     .copy {
         h1 {
             margin-bottom: 3rem;
         }
     }
     &.home {
         background: #0d1640 url(/public/img/nyc-skyline.png) no-repeat center bottom/auto 50%;
         padding: 4rem 0;
         h1 {
             margin-bottom: 4.625rem;
         }
         p {
             font-size: 1.325rem;
             margin: 0 auto 5rem;
             max-width: 80%;
         }
         img {
             margin: 0 auto;
             max-width: 300px;
         }
         .tiles.col-2 {
             > * {
                 text-align: center;
             }
         }
     }
 }

 .tiles {
     > div {
         text-align: center;
     }
 }

 .cta {
     background: #fff;
     border: none;
     padding: 5rem 0;
     text-align: center;
     h2,
     h3,
     h4 {
         color: $brand-navy;
     }
     p {
         color: #383f63;
     }
     .tiles {
         margin-bottom: 2rem;
         div {
             border-radius: 5px;
             overflow: hidden;
             min-height: 12rem;
             padding: 0;
             position: relative;
             text-align: center;
             a {
                 @include transition;
                 background-color: #383f63;
                 display: block;
                 font-size: 1.5rem;
                 height: 100%;
                 left: 0;
                 margin-bottom: 0;
                 padding: 3rem;
                 position: absolute;
                 text-align: left;
                 text-transform: uppercase;
                 top: 0;
                 width: 100%;
                 &:hover {
                     background: $brand-accent-blue;
                     color: #fff;
                 }
             }
             &:after {
                 @include fontawesome;
                 @include transition;
                 bottom: 1rem;
                 content: "\f105";
                 color: #fff;
                 font-size: 2rem;
                 position: absolute;
                 left: 3rem;
                 z-index: 10;
             }
             h3 {
                 color: #fff;
                 font-size: 1.25rem;
                 position: relative;
                 text-align: left;
                 text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
                 z-index: 10;
             }
             i {
                 bottom: -1rem;
                 color: rgba(0,0,0,0.3);
                 font-size: 10rem;
                 position: absolute;
                 right: -2rem;
                 z-index: 5;
                 &.fa-quote-right {
                     bottom: -1.5rem;
                     font-size: 9rem;
                 }
             }
             &:hover {
                 &::after {
                     left: 90%;
                 }
             }
         }
     }
 }

 .services {
     .flex {
         display: flex;
         flex-direction: column;
     }
 }

.services-group {
    margin-bottom: 2rem;
    padding-left: 3rem;
    position: relative;
    h3 {
        line-height: 1;
    }
    &::before {
        background-color: $brand-bright-blue;
        content: "";
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 3px;
    }
}

 /* = Media Queries = */
 @media only screen and (min-width: $medium) {
     main section {
         padding: 3rem;
     }
     /* = Typography = */
     h1,
     h2,
     h3,
     h4,
     h5 {
         margin-bottom: 3rem;
     }
     h1 {
         font-size: 3rem;
     }

     h2 {
         font-size: 2.5rem;
     }

     h3 {
         font-size: 2.25rem;
     }

     h4 {
         font-size: 1.5rem;
     }

     h5 {
         font-size: 1.25rem;
     }

     .branding {
         a {
             max-width: 200px;
         }
         img {
             max-width: 200px;
         }
     }

     /* = Layout = */
     aside.primary {
         float: right;
         margin-bottom: 0;
         width: 20%;
         nav ul {
             text-align: left;
         }
     }
     aside.primary ~ main {
         float: left;
         padding-right: 2rem;
         width: 80%;
     }

     header.primary {
         .menu-toggle {
             right: 3rem;
         }
     }

     nav.primary {
         ul li {
             font-size: 2rem;
         }
     }

     nav.secondary {
         .contact {
             text-align: right;
             position: absolute;
             right: 9rem;
             top: 3.25rem;
         }
     }
     .hero {
         h2 {
             font-size: 2.5rem;
         }
         padding: 3rem 0;
     }

     .hero.home {
         background: #0d1640 url(/public/img/nyc-skyline.png) no-repeat center bottom/100% auto;
         padding: 8rem 0;
         h1 {
             text-align: left;
         }
         p {
             margin: 0 0 5rem;
             text-align: left;
         }
         img {
             max-width: 320px;
         }
         .tiles.col-2 {
             > * {
                 text-align: left;
             }
             > div {
                 align-items: flex-start;
                 display: flex;
                 flex-direction: column;
                 justify-content: flex-start;
             }
             img {
                 height: auto;
                 max-width: 320px;
             }
         }
     }

     section.about {
         .container > div {
             align-items: center;
             flex-direction: row;
             div {
                 flex-basis: 60%;
                 justify-content: space-bewteen;
                 padding: 2rem 0;
             }
             .asset {
                 flex-basis: 40%;
                 padding-left: 4rem;
             }
             p {
                font-size: 1.2rem;
             }
             &.reverse {
                 div {
                     order: 2;
                 }
                 .asset {
                     order: 1;
                     padding-left: 0;
                     padding-right: 4rem;
                 }
             }
         }
     }

     .services {
         .flex {
             flex-direction: row;
             h3 {
                 text-align: left;
             }
             > div {
                 flex-basis: 50%;
             }
             .assets img {
                 margin: 0 0 2rem 2rem;
             }
         }
     }

     .services-group {
        margin-bottom: 5rem;
    }

    .contact-info {
        p {
            font-size: 1.5rem;
            text-align: right;
        }
     }

     .cta {
         .tiles {
             div {
                 min-height: 15rem;
             }
             h3 {
                 font-size: 1.75rem;
             }
         }
     }
 }


 @media only screen and (min-width: $large) {
     /* = Layout = */
     .wrapper {
         overflow: auto;
     }


     // .desktop {
     //     display: flex;
     // }

     aside.primary {
         width: 25%;
     }
     aside.primary ~ main {
         width: 70%;
     }
 }

 /* = Print Styles = */
 @media print {
     * {
         background-color: transparent !important;
         box-shadow: none !important;
         color: #000 !important; /* Black prints faster: h5bp.com/s */
         text-shadow: none !important;
     }

     a {
         text-decoration: underline;
     }

     pre,
     blockquote,
     tr,
     img,
     h1,
     h2,
     h3,
     h4,
     h5,
     h6 {
         page-break-inside: avoid;
     }

     thead {
         display: table-header-group;
     }

     @page {
         margin: 0.5cm;
     }

     p,
     h2,
     h3 {
         orphans: 3;
         widows: 3;
     }
 }
